import React, { PureComponent } from "react";

import { withStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = {
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "20vh"
  }
};


class Loading extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    }

    this.timer = setTimeout(this.show, 500);
  }

  componentWillUnMount() {
    clearTimeout(this.timer);
  }

  setTimeout = () => {
    this.setState({ show: true });
  }

  render() {

    const { classes } = this.props;

    if (!this.state.show) {
      return null;
    }

    return (
      <div className={classes.root} >
        <CircularProgress />
      </div>
    );
  }
}

export default withStyles(styles)(Loading);

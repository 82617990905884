import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';

import Login from "./Login";

import Loading from "./components/Loading";

//import Consilium from "./consilium";

//TODO: disabled code split during development.
import Loadable from 'react-loadable';

//window.LOG_LEVEL='DEBUG'

const Loading2 = () => (
  <div style={{ display: "flex" }} >
    <Loading />
  </div>
);

const Consilium = Loadable({
  loader: () => import('./consilium' /* webpackChunkName: "consilium" */),
  loading: Loading2,
});

Amplify.configure({
  Auth: {
    //mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_4TxDnC5E4',
    identityPoolId: 'us-west-2:9be65c48-8a72-4fe4-a8ba-4bd5e96334f5',
    userPoolWebClientId: '36vo9v03kaeahvp8b9h3om4f6o',
    //oauth: oauth
  },
  Analytics: {
    disabled: true,
    autoSessionRecord: false
  }
});

const AuthRoute = ({ component: C, authStatus, onAuthEvent, authProtected, ...rest}) => (
  <Route
    {...rest}
    render={ props =>
        (!authProtected || authStatus) ? (
          <C {...props} authStatus={authStatus} onAuthEvent={onAuthEvent} />
        ) : (
          <Redirect to='/login' />
        )
    }
  />
);

//const wait = ms => new Promise((r, j) => setTimeout(r, ms));



class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticating: true,
      authStatus: false
    };
  }

  handleAuthEvent = event => {
    //console.log('handle auth event: ' + event.type);
    let status = false;
    if (event.type === "AUTHENTICATED") status = true;
    if (status === this.state.authStatus) return;
    this.setState({ authStatus: status });
  }

  async componentDidMount() {
    try {
      let timeout = new Promise((resolve, reject) => {
        setTimeout(reject, 10000, new Error('Timed out'));
      });
      /*const foo =*/ await Promise.race([Auth.currentSession(), timeout]);
      //console.log("foo");
      //console.log(foo);
      this.setState({ authStatus: true });
    }
    catch(e) {
      //console.log("foo-e");
      //console.log(e);
      /*
      if (e.code === "PasswordResetRequiredException") {
        console.log("reset required");
        Auth.forgotPasswordSubmit("phil", "240512", "FuzzyPuppie-1");
      }
      */
      if (e !== 'No current user') {
        console.error(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  render() {

    const authProps = {
      authStatus: this.state.authStatus,
      onAuthEvent: this.handleAuthEvent
    };

    if (this.state.isAuthenticating) {
      return (
        <div style={{ display: "flex" }} >
          <Loading />
        </div>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <div>
          <Switch>
            <AuthRoute exact strict path='/' component={Consilium} {...authProps} authProtected={true} />
            <AuthRoute exact strict path='/login' component={Login} {...authProps} authProtected={false} />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(App);

//import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

/*
const oauth = {
  // Domain name
  domain: 'planner.auth.us-west-2.amazoncognito.com',

  // Authorized scopes
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],

  // Callback URL
  redirectSignIn: 'https://fluffy.local:3000',

  // Sign out URL
  redirectSignOut: 'https://www.google.com',

  // Use Authorization code grant.
  responseType: 'code',

  // For Cognito hosted ui specified options.
  options: {
    AdvancedSecurityDataCollectionFlag: true
  }
}
*/

/*
Amplify.configure({
  Auth: {
    //mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_4TxDnC5E4',
    identityPoolId: 'us-west-2:9be65c48-8a72-4fe4-a8ba-4bd5e96334f5',
    userPoolWebClientId: '36vo9v03kaeahvp8b9h3om4f6o',
    //oauth: oauth
  },
});
*/


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
//registerServiceWorker();

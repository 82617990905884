import { Auth } from 'aws-amplify';
import React, { Component } from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: blue
  },
});

const Dots = () => (
  <React.Fragment>
    <svg viewBox="-50 -50 100 100" width={18} transform="rotate(22.5)"
      fill="rgba(0,0,0,0.36)" style={{verticalAlign: "text-bottom", paddingBottom:2}}
    >
      <circle cx={-32} cy={-32} r={18} />
      <circle cx={32} cy={-32} r={18} />
      <circle cx={-32} cy={32} r={18} />
      <circle cx={32} cy={32} r={18} />
    </svg>
  </React.Fragment>
);

const Bars = () => (
  <React.Fragment>
    <svg viewBox="0 0 84 72" width={24}
      fill="rgba(0,0,0,0.36)" style={{verticalAlign: "text-bottom", paddingBottom:1}}
    >
      <rect x={2} y={4} width={44} height={18} />
      <rect x={32} y={28} width={52} height={18} />
      <rect x={14} y={52} width={48} height={18} />
    </svg>
  </React.Fragment>
)

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      authData: null,
      authState: "SIGNIN",
      username: '',
      password: '',
      code: ''
    };
  }

  handleSubmit = async event => {
    event.preventDefault();

    //console.log('handle submit');

    const { authState } = this.state;

    if (authState === "SIGNIN") {
      this.handleSignIn();
    } else if (authState === "VERIFY") {
      this.handleVerify();
    } else if (authState === "UPDATE") {
      this.handleUpdate();
    }
  };

  handleSignIn = async () => {

    try {
      //const foo = await Auth.forgotPasswordSubmit("phil", "851649", "FuzzyPuppy-1");
      const authData = await Auth.signIn(this.state.username, this.state.password);
      console.log(authData);
      //console.log("handle signin");
      //console.log(this.state);
      //console.log(authData);

      if (authData.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({ authState: "UPDATE", authData: authData, password: "" })
      } else if (authData.challengeName === 'SMS_MFA') {
        this.setState({ authState: "VERIFY", authData: authData, password: "" })
      } else {
        this.setState({ authState: "AUTHENTICATED", authData: authData, password: "" })
        this.props.onAuthEvent({ type: "AUTHENTICATED" });
        this.props.history.push("/");
      }
    }
    catch(e) {
      console.log(e);
    }
  };

  handleVerify = async () => {

    const { code } = this.state;

    try {
      const authData = await Auth.confirmSignIn(this.state.authData, code, 'SMS_MFA');
      //console.log("handle verify");
      //console.log(this.state);
      //console.log(authData);

      this.setState({ authState: "AUTHENTICATED", authData: authData, code: "" })
      this.props.onAuthEvent({ type: "AUTHENTICATED" });
      this.props.history.push("/");
    } catch(e) {
      console.log(e);
    }
  };

  handleUpdate = async () => {

    const { password } = this.state;

    try {
      const authData = await Auth.completeNewPassword(this.state.authData, password, {name: 'Test User'});
      //console.log("handle update");
      //console.log(this.state);
      //console.log(authData);

      if (authData.challengeName === 'SMS_MFA') {
        this.setState({ authState: "VERIFY", authData: authData })
      } else {
        this.setState({ authState: "AUTHENTICATED", authData: authData, password: "" })
        this.props.onAuthEvent({ type: "AUTHENTICATED" });
        this.props.history.push("/");
      }
    } catch(e) {
      console.log(e);
    }
  };

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {

    const { authState, authData } = this.state;

    if (authState === "AUTHENTICATED") {
      return null;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Paper style={{marginTop: 120, marginLeft: "auto", marginRight: "auto", width: "30%", minWidth: 360,
            paddingTop: 48, paddingBottom: 24, paddingLeft: 24, paddingRight: 24 }}>
            <div style={{textAlign: "center"}} >
              <Dots />
              <div
                style={{display: "inline-flex", fontFamily: "Roboto", fontWeight: 400,
                  color: "rgba(0,0,0,0.78)", fontSize: "1.25rem", paddingLeft: 16, paddingRight: 12}}
              >
                consilium
              </div>
              <Bars />
            </div>
            {authState === "SIGNIN" &&
              <React.Fragment>
                <div style={{paddingTop: 48}} >
                  <TextField fullWidth
                    label="User Name"
                    value={this.state.username}
                    InputProps={{type: "text", name: "username"}}
                    onChange={this.handleInput}
                  />
                </div>
                <div style={{paddingTop: 48}} >
                  <TextField fullWidth
                    label="Password"
                    value={this.state.password}
                    autoComplete="off"
                    InputProps={{type: "password", name: "password"}}
                    onChange={this.handleInput}
                  />
                </div>
              </React.Fragment>
            }
            {authState === "VERIFY" &&
              <div style={{paddingTop: 48}} >
                <Typography variant="body1" gutterBottom >
                  Enter the code sent to {authData.challengeParam.CODE_DELIVERY_DESTINATION}.
                </Typography>
                <TextField fullWidth
                  label="Code"
                  value={this.state.code}
                  autoComplete="off"
                  InputProps={{type: "text", name: "code"}}
                  onChange={this.handleInput}
                />
              </div>
            }
            {authState === "UPDATE" &&
              <div style={{paddingTop: 48}} >
                <Typography variant="body1" gutterBottom >
                  New password required.
                </Typography>
                <TextField fullWidth
                  label="New Password"
                  value={this.state.password}
                  autoComplete="off"
                  InputProps={{type: "password", name: "password"}}
                  onChange={this.handleInput}
                />
              </div>
            }
            <div style={{paddingTop: 24}} >
              <Button
                fullWidth
                variant="raised"
                color="primary"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Login;
